import React, { useState, useEffect } from "react";
import { DarkThemeToggle, Tabs } from "flowbite-react";
import { Blockquote } from "flowbite-react";
import { MdWork } from "react-icons/md";
import { IoGitNetworkOutline } from "react-icons/io5";

import Logo_Dark_Theme from "./assets/Logo_Dark_Theme.png";
import Logo_Light_Theme from "./assets/Logo_Light_Theme.png";

import ProjectList from "./Projects/ProjectList";
import Experience from "./Experience/Experience";

const App = () => {
  const [activeTab, setActiveTab] = useState("projects");
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const tabLabels = ["projects", "experience"];

  const handleTabChange = (tabIndex: number) => {
    setActiveTab(tabLabels[tabIndex]);
  };

  const handleThemeToggle = () => {
    setIsDarkTheme(!isDarkTheme);
    if (!isDarkTheme) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  // Sync theme on mount to match the initial class in the document
  useEffect(() => {
    const isCurrentlyDark = document.documentElement.classList.contains("dark");
    setIsDarkTheme(isCurrentlyDark);
  }, []);

  // Check authorization status from localStorage
  useEffect(() => {
    const isHaveAccess = localStorage.getItem("isAuthorized");
    if (isHaveAccess) {
      setIsAuthorized(true);
    }
  }, []);

  // Function to handle access key prompt and validation
  const requestAccessKey = () => {
    const accessKey = prompt("Enter your access key:");
    if (accessKey === "2100031265") {
      localStorage.setItem("isAuthorized", "true");
      setIsAuthorized(true); // Grant access if key is correct
    } else {
      alert("Invalid access key. Try again.");
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "projects":
        return (
          <>
            <Blockquote className="my-6 text-center text-3xl font-bold text-gray-800 dark:text-white">
              ⭐💫 Innovative Ideas, Impactful Solutions! 💫⭐
            </Blockquote>
            <ProjectList />
          </>
        );
      case "experience":
        return (
          <>
            <Blockquote className="my-6 text-center text-3xl font-bold text-gray-800 dark:text-white">
              ⭐💫 Hands deep in code, heart set on solutions! 💫⭐
            </Blockquote>
            <Experience />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isAuthorized ? (
        <main className="min-h-screen bg-white p-4 dark:bg-gray-800">
          <div className="flex flex-col items-center justify-between md:flex-row">
            {/* Logo Section */}
            <div className="hidden md:block">
              <img
                src={isDarkTheme ? Logo_Dark_Theme : Logo_Light_Theme}
                alt="logo"
                className="h-auto w-6 bg-transparent md:w-10 lg:w-14"
              />
            </div>

            {/* Tabs Section */}
            <div className="mt-4 md:mt-0">
              <Tabs
                aria-label="Default tabs"
                style="underline"
                onActiveTabChange={handleTabChange}
              >
                <Tabs.Item
                  active={activeTab === "projects"}
                  title={
                    <span className="text-gray-800 dark:text-white">
                      Projects
                    </span>
                  }
                  icon={IoGitNetworkOutline}
                />
                <Tabs.Item
                  active={activeTab === "experience"}
                  title={
                    <span className="text-gray-800 dark:text-white">
                      Experience
                    </span>
                  }
                  icon={MdWork}
                />
              </Tabs>
            </div>

            {/* Dark Theme Toggle Section */}
            <div className="hidden md:block">
              <DarkThemeToggle
                className="rounded-lg p-2.5 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                onClick={handleThemeToggle}
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="mt-2 w-full">{renderTabContent()}</div>
        </main>
      ) : (
        <div className="flex h-screen flex-col items-center justify-center">
          <h1>You are not authorized to view this page</h1>
          <button
            className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
            onClick={requestAccessKey}
          >
            Enter Access Key
          </button>
        </div>
      )}
    </>
  );
};

export default App;
