import React from "react";
import { Button, Timeline } from "flowbite-react";
import { HiArrowNarrowRight, HiCalendar } from "react-icons/hi";

export default function Experience() {
  return (
    <div className="m-5">
      <Timeline>
        <Timeline.Item>
          <Timeline.Point icon={HiCalendar} />
          <Timeline.Content>
            <Timeline.Time>9th September 2024 - Present</Timeline.Time>
            <Timeline.Title>Safertek</Timeline.Title>
            <Timeline.Body>
              <strong>App Developer</strong>
              <br />
              As an App Developer at Safertek,{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " I am working collaboratively within a team to develop
                applications "
              </span>
              , focusing on creating high-quality products that meet user needs.{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " I am also contributing to enhancing the company's custom
                styling library by integrating robust features and custom logic,
                ensuring a consistent and appealing user experience across all
                applications.{" "}
              </span>
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point icon={HiCalendar} />
          <Timeline.Content>
            <Timeline.Time>8th, July 2024 - 31st, October 2024</Timeline.Time>
            <Timeline.Title>Dusky</Timeline.Title>
            <Timeline.Body>
              <strong>SDE</strong>
              <br />
              Developed the{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " Auto Dev Tool from scratch after initially working on an
                open-source project for a month "
              </span>
              , using its theme to implement specific features required by the
              company for{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " automating code analysis and correction, boosting productivity
                by 70% "
              </span>
              . Additionally, created a company website that increased client
              acquisition by 30% through improved design and engagement.
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point icon={HiCalendar} />
          <Timeline.Content>
            <Timeline.Time>23rd August - 24th August, 2023</Timeline.Time>
            <Timeline.Title>Google Hackathon</Timeline.Title>
            <Timeline.Body>
              <strong>Semi-Finalist</strong>
              <br />
              Developed an{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " app for small and marginal farmers "
              </span>{" "}
              that reduces costs and enhances yield.{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " Out of numerous applicants across India, including startups,
                only 50 teams were selected, and our team placed in the top 12
                as semi-finalists. We were closely monitored by Google
                employees, including senior member Guru Bhat, along with other
                industry leads. "
              </span>
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point icon={HiCalendar} />
          <Timeline.Content>
            <Timeline.Time>15th July - 16th July, 2023</Timeline.Time>
            <Timeline.Title>JP Morgan & Chase Hackathon</Timeline.Title>
            <Timeline.Body>
              <strong>Participant</strong>
              <br />
              Developed a{" "}
              <span className="font-medium text-gray-800 dark:text-white">
                " website for donation management for old age and children ,
                with mentorship and guidance provided by JP Morgan Chase
                employees "
              </span>{" "}
              throughout the process.
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
    </div>
  );
}
